var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, width: "350" },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("cancel")
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Confirm Move ")]),
          _c("v-card-text", [
            _vm._v(
              " Moving this feature will result in deletion of the original. Continue? "
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { color: "grey darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { color: "success" },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" Continue ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }