var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, width: "600" },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("update:value", false)
        },
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "fill" } },
        [
          _c("v-card-title", { class: _vm.title }, [
            _vm._v("Move/Copy Ad Feature"),
          ]),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0 px-6 pt-6", attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: { justify: "center", align: "center", dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-autocomplete", {
                            class: _vm.body,
                            attrs: {
                              label: "Destination Ad",
                              items: _vm.ads,
                              disabled: _vm.loading,
                              loading: _vm.loadingAds,
                              "auto-select-first": "",
                              "item-text": "ad_name",
                              "item-value": "id",
                              "background-color": "input",
                              autocomplete: "off",
                              outlined: "",
                              dense: "",
                              "menu-props": { maxHeight: 220, rounded: "" },
                              clearable: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            " " + _vm._s(item.ad_name) + " "
                                          ),
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.ad_group_name) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.destinationAdId,
                              callback: function ($$v) {
                                _vm.destinationAdId = $$v
                              },
                              expression: "destinationAdId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      attrs: { justify: "center", align: "center", dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-autocomplete", {
                            class: _vm.body,
                            attrs: {
                              label: "Destination Page",
                              items: _vm.pages,
                              disabled: _vm.loading,
                              loading: _vm.pages.length === 0,
                              "item-text": "name",
                              "item-value": "constant",
                              "background-color": "input",
                              autocomplete: "off",
                              outlined: "",
                              dense: "",
                              "menu-props": { maxHeight: 220, rounded: "" },
                              clearable: "",
                            },
                            model: {
                              value: _vm.destinationPage,
                              callback: function ($$v) {
                                _vm.destinationPage = $$v
                              },
                              expression: "destinationPage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-autocomplete", {
                            class: _vm.body,
                            attrs: {
                              label: "Destination Position",
                              items: _vm.featurePositions,
                              disabled: _vm.loading,
                              loading: _vm.loadingFeaturePositions,
                              "item-text": "name",
                              "item-value": "id",
                              "background-color": "input",
                              autocomplete: "off",
                              outlined: "",
                              dense: "",
                              "menu-props": { maxHeight: 220, rounded: "" },
                              clearable: "",
                            },
                            model: {
                              value: _vm.destinationFeaturePositionId,
                              callback: function ($$v) {
                                _vm.destinationFeaturePositionId = $$v
                              },
                              expression: "destinationFeaturePositionId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "fill" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text ml-2",
                  class: _vm.body,
                  attrs: { disabled: _vm.moveCopyDisabled, color: "warning" },
                  on: { click: _vm.openConfirmDialog },
                },
                [_vm._v(" Move ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text ml-2",
                  class: _vm.body,
                  attrs: { disabled: _vm.moveCopyDisabled, color: "save" },
                  on: {
                    click: function ($event) {
                      return _vm.copy("copy")
                    },
                  },
                },
                [_vm._v(" Copy ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.confirmDialog
        ? _c("FeatureMoveDialog", {
            on: {
              cancel: _vm.closeConfirmDialog,
              confirmMove: _vm.confirmMove,
            },
            model: {
              value: _vm.confirmDialog,
              callback: function ($$v) {
                _vm.confirmDialog = $$v
              },
              expression: "confirmDialog",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }